.reader{
	position: fixed ;
	height: 100% ;
	width : 100% ;
	overflow: hidden ;
	background-color: #fff ;

	&__header {
		position: fixed ;
		top : 0 ;
		left: 0 ;
		width: 100% ;
		height: 8rem ;
		padding: 0 8rem ;
		display: flex ;
		align-items: center ;
		justify-content: space-between ;
		z-index: 100 ;
		background-color: inherit ;
		transform: translateY(-100%);
		transition: all 200ms ease-out ;

		@include respond(tabLand) {
			padding: 0 2rem ; 
		}

		&--show {
			transform: translateY(0);
		}

		&__center {
			position: absolute ;
			left: 50% ;
			top : 50% ;
			transform: translate(-50%,-50%);
			@include respond(phone){ display: none }
		}

		&__left,
		&__right {
			display: flex ;
			& > * + * {
				margin-left: 0.5rem ;
			}
		}

		&__left {
			position: relative ;
			transition: all 200ms ease-out ;
			transform: translateY(8rem);

			@include respond(tabPort) {
				transform: translateY(0rem) !important;
			}

			&__timer {
				display: flex ;
				align-items: center ;
				margin-left: 1rem ;
			}
		}

		&--show & {
			&__left {
				transform: translateY(0rem);
			}
		}

		&__right {
			&__button {
				&--active {
					background-color: $color-secondary ;
					color: #fff ;

					&:hover {
						background-color: $color-secondary ;
						color: #fff ;
					}
				}
			}
			&__hide-on-mobile {
				@include respond(tabPort){
					display: none ;
				}
			}
		}
	}

	&__container {
		position: relative ;
		width: 100% ;
		height: 100% ;
		overflow: hidden ;
		padding: 8rem ;

		@include respond(tabPort) {
			padding: 4rem 2rem ;
		}

		&__book {
			position: relative ;
			width: 100% ;
			height: 100% ;
		}

		&__prev-btn,
		&__next-btn {
			position: fixed ;
			height: 100% ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			width: 8rem ;
			left: 0 ;
			top: 0 ;
			z-index: 10 ;

			@include respond(tabPort) {
				display: none ;
			}

			@include respond(phone) {
				display: none ;
			}

			&__button {
				width : 5rem ;
				height: 5rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;
				border-radius: 50% ;
				cursor: pointer ;
				transition: all 100ms ease-out ;
				opacity: 0 ;

				&>svg {
					stroke : $color-neutral-500 ;	
				}

				&:hover {
					background-color: $color-neutral-200;
					&>svg {
						stroke : $color-neutral-800 ;
					}
				}
			}

			&:hover & {
				&__button {
					opacity: 1 ;
				}
			}
		}
	
		&__next-btn {
			left: unset ;
			right: 0 ;
		}

		&__bookmark {
			position: absolute ;
			width: 3rem ;
			height: 4rem ;
			right: 4rem ;
			top: 0rem ;
			background-color: $color-secondary ;
			z-index: 99 ;
			clip-path: polygon(
				0% 0%,
				100% 0%,
				100% 100%,
				50% 60%,
				0% 100%	
			);
			transform: translateY(calc(-100% - 8rem));
			transition: all 300ms ease-out ;

			&--show {
				transform: none ;
			}
		}

		&__context-menu-container {
			position: fixed ;
			top : 50% ;
			left: 50% ;
			transform: translate(-50%,-50%) scale(0);
			transition: all 100ms ease-out ;
			opacity: 0 ;
			pointer-events: none ;

			&--show {
				opacity: 1 ;
				pointer-events: all ;
				transform: translate(-50%,-50%) scale(1);
			}
		}
	}

	&__nav{
		position: fixed ;
		bottom : 0 ;
		left: 0 ;
		height: 10rem ;
		width: 100% ;
		padding: 2rem 8rem ;
		background-color: transparent ;
		transform: translateY(4rem); 
		transition: all 200ms ease-out;
		z-index: 50 ;
		--offset : 6rem ;
		pointer-events: none ;

		&__value {
			position: relative ;
			display: flex ;
			align-items: center ;
			justify-content: space-between ;
			transition: all 200ms ease-out;
			margin-bottom: 1.5rem ;

			&__chapter-title {
				white-space: nowrap ;
				overflow: hidden ;
				text-overflow: ellipsis ;
				margin-right: 4rem ;
			}
		}

		&__progress {
			width: 100% ;
			transition: all 200ms ease-out;
			transform: translateY(var(--offset));
		}

		@include respond(tabLand) {
			padding: 2rem ; 
			transform: translateY(3rem);
		}

		&--show {
			transform: translateY(0) !important;
			--offset : 0rem ; 
			background-color: inherit ;
			pointer-events: all ;
		}

	}
}

[data-theme="dark"] .reader {
	background-color: #000 ;
	color: $color-white;

	&__container{
		&__prev-btn,
		&__next-btn {
			&__button {
				&:hover {
					color: #fff ;
					background-color: $color-neutral-800;
					&>svg {
						stroke: #fff ;
					}
				}
			}
		}
	}
	
	.button {
		color: $color-neutral-500;
	
		&--icon {
			&:hover {
				background-color: $color-neutral-700 ;
				color: $color-neutral-100;
			}
		}
	}

	&__header {
		background-color: #000 ;
		color: #fff ;

		&__right {
			&__button {
				&--active {
					background-color: $color-secondary ;
					color: #fff !important ;
					&:hover {
						background-color: $color-secondary ;
						color: #fff ;
					}
				}
			}
		}
	}
	
	&__nav {
		&__progress-value{
			color: $color-neutral-100 ;
		}
	}
}


;@import "sass-embedded-legacy-load-done:26";