.range-slider {
    --progress:0;
    position: relative ;
    width: 100% ;
    height: 2.5rem ;
    cursor: pointer;
    overflow: hidden ;

    &__input {
        -webkit-appearance: none ;
        appearance: none ;
        position: absolute ;
        top : 50% ;
        left: 0 ;
        width: 100% ;
        height: 100% ;
        background-color: transparent ;
        transform: translateY(-50%);
        cursor: pointer ;
        z-index: 1 ;
    }

    &::before {
        content: "" ;
        position: absolute ;
        left : 0 ;
        top  : 50% ;
        width : 100% ;
        height: 0.5rem ;
        background-color: $color-neutral-300 ;
        pointer-events: none ;
        transform: translateY(-50%);
    }

    &::after {
        content: "" ;
        position: absolute ;
        left  : 0 ;
        top: 50% ;
        width : 100% ;
        height: 0.5rem ;
        background-color: $color-secondary;
        transform: translate(calc((1 - var(--progress))  * -100%),-50%); 
        pointer-events: none ;
    }

}
;@import "sass-embedded-legacy-load-done:24";