.number-input {
    display: flex ;
    align-items: center ;

    &__button {
        width: 4rem ;
        height: 4rem ;
        display: flex ;
        align-items: center ;
        justify-content: center ;
        border: 0.1rem solid $color-neutral-300 ;
        color: $color-neutral-500 ;
        cursor: pointer ;

        &:hover {
            background-color: $color-neutral-200 ;
            color: $color-neutral-900 ;
        }
    }

    &__value {
        width: 8ch ;
        text-align: center ;
        font-weight: 700 ;
        color: $color-neutral-700 ;
    }
}

[data-theme="dark"] .number-input {
    &__button {
        border-color: $color-neutral-700 ;
        color: $color-neutral-500 ;

        &:hover {
            background-color: $color-neutral-800 ;
            color: $color-white ;
        }
    }

    &__value { color: $color-white; }
}
;@import "sass-embedded-legacy-load-done:25";