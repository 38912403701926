.panel {
	color: $color-neutral-700 ;

	&__toc {
		padding: 1rem ;
		&__item {
			padding: 1.25rem 1.75rem ;
			border-radius: 0.4rem ;
			cursor: pointer ;

			&:hover {
				background-color: $color-neutral-200 ;
			}
		}
	}

	&__annotation {
		padding: 1rem ;
		overflow-y: auto ;

		&__item {
			padding: 1.5rem ;
			padding-bottom: 1rem ;
			border-radius: 0.4rem ;
			background-color: $color-neutral-200 ;

			&__container {
				position: relative ;
				display: flex ;
				cursor: pointer ;
			}

			&__color {
				border-radius: 0.4rem ;
				width : 0.5rem ;
				flex: 0 0 0.5rem ;
				margin-right: 1rem ;
			}

			&__name {
				flex: 1 ;
				height: 100% ;
			}

			&>.button {
				margin-top: 1rem ;
				display: block ;
				margin-left: auto ;
			}
		}

		&__item + &__item {
			margin-top: 1rem ;
		}
	}

	&__customizer {
		padding: 2rem ;

		&__field {
			&__lable {
				margin-bottom: 1rem ;
			}

			&__input {
				display: flex ;

				&__chip{
					width : 5rem ; 
					height: 5rem ;
					border-radius: 50% ;
					box-shadow: 0 0 0 0.2rem currentColor ;
					display: flex ;
					align-items: center ;
					justify-content: center ;
					font-size: 2rem ;
					font-weight: 700 ;
					box-shadow: $shadow-sm;
					cursor: pointer ;

					&--selected {
						box-shadow: 0 0 0 0.24rem $color-secondary !important ;
					}
				}

				&__font {
					flex: 1 ;
					box-shadow: 0 0 0 0.1rem $color-neutral-300 ;
					display: flex ;
					align-items: center ;
					justify-content: center ;
					flex-direction: column ;
					padding: 2rem ;
					color: $color-neutral-700 ;
					cursor: pointer ;

					&__demo {
						font-size: 3rem ; 
						font-family: 700 ;
					}

					&__name {
						margin-top: 1rem ;
						font-weight: 500 ;
						text-align: center ;
					}

					&:hover {
						background-color: $color-neutral-200 ;
						color: $color-neutral-900 ;
					}

					&--selected {
						box-shadow: 0 0 0 0.2rem $color-secondary ;
					}
				}

				>* + * {
					margin-left: 1.5rem ;
				}

				&--fonts {
					flex-wrap: wrap ;
				}
			}
			
		}

		&__field + &__field {
			margin-top: 3rem ;

			@include respond(phone) {
				margin-top: 1.5rem ;
			}
		}
	}

	&__empty {
		text-align: center ; 
		padding: 2rem ;
	}
}

[data-theme="dark"] .panel {
	color: $color-neutral-100 ; 

	&__toc {
		&__item {
			&:hover {
				background-color: $color-neutral-800 ;
			}
		}
	}

	&__annotation {
		&__item {
			background-color: $color-neutral-800 ;
		}
	}

	&__customizer {
		&__field {
			&__input {
				&__font {
					box-shadow: 0 0 0 0.1rem $color-neutral-700 ;
					color: $color-neutral-200  ;

					&:hover {
						background-color: $color-neutral-800 ;
						color: $color-white ;
					}

					&--selected {
						box-shadow: 0 0 0 0.2rem $color-secondary !important ;
					}
				}
			}
		}
	}
}
;@import "sass-embedded-legacy-load-done:19";