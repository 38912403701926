.readtime {
	display: flex ;
	align-items: center ;
	margin-left: 1rem ;
	color: $color-neutral-500 ;
	&__time {
		margin-left: 1rem ;
	}
}

[data-theme="dark"] .readtime {
	color: $color-neutral-100 ;
}
;@import "sass-embedded-legacy-load-done:21";