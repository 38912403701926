.utils{

	&__visibility{
		&--hide { visibility: hidden; };
		&--show { visibility: visible; };
	}

	
	&__height{
		&--0{height:0;	}
		&--v{ &-100{height:100vh;} }
		&--p{ &-100{height:100%;} }
	}
	
	&__width{
		&--auto { width: auto !important; }
		&--fill { width: 100% !important; }
		&--half { width: 50% !important; }
		&--60 {width: 60% !important;}
		&--50 {width: 50% !important;}
		&--40 {width: 40% !important;}
		&--25 {width: 25% !important;}
		&--mc{width: -moz-max-content; width: max-content;}
	}

	&__cursor{
		&--alias{ cursor: alias !important; }
		&--all-scroll { cursor: all-scroll !important; }
		&--auto { cursor: auto !important; }
		&--cell { cursor: cell !important; }
		&--context-menu { cursor: context-menu !important; }
		&--col-resize { cursor: col-resize !important; }
		&--copy { cursor: copy !important; }
		&--crosshair { cursor: crosshair !important; }
		&--default { cursor: default !important; }
		&--e-resize { cursor: e-resize !important; }
		&--ew-resize { cursor: ew-resize !important; }
		&--grab { cursor: -webkit-grab; cursor: grab !important; }
		&--grabbing { cursor: -webkit-grabbing; cursor: grabbing !important; }
		&--help { cursor: help !important; }
		&--move { cursor: move !important; }
		&--n-resize { cursor: n-resize !important; }
		&--ne-resize { cursor: ne-resize !important; }
		&--nesw-resize { cursor: nesw-resize !important; }
		&--ns-resize { cursor: ns-resize !important; }
		&--nw-resize { cursor: nw-resize !important; }
		&--nwse-resize { cursor: nwse-resize !important; }
		&--no-drop { cursor: no-drop !important; }
		&--none { cursor: none !important; }
		&--not-allowed { cursor: not-allowed !important; }
		&--pointer { cursor: pointer !important; }
		&--progress { cursor: progress !important; }
		&--row-resize { cursor: row-resize !important; }
		&--s-resize { cursor: s-resize !important; }
		&--se-resize { cursor: se-resize !important; }
		&--sw-resize { cursor: sw-resize !important; }
		&--text { cursor: text !important; }
		&--w-resize { cursor: w-resize !important; }
		&--wait { cursor: wait !important; }
		&--zoom-in { cursor: zoom-in !important; }
		&--zoom-out {cursor: zoom-out !important; }
	}

	&__margin{
		&__top{
			&--n { margin-top: 0 !important; }
			&--xs { margin-top: 0.8rem !important; }
			&--s { margin-top: 1.6rem !important; }
			&--m { margin-top: 2.4rem !important; @include respond(phone){ margin-top: 4rem !important; } }
			&--b { margin-top: 8rem !important; }
			&--l { margin-top: 14rem !important; @include respond(phone){ margin-top: 10rem !important; } }
		}

		&__bottom{
			&--n { margin-bottom: 0 !important; }
			&--xs { margin-bottom: 0.8rem !important; }
			&--s { margin-bottom: 1.6rem !important; }
			&--m { margin-bottom: 2.4rem !important; }
			&--b { margin-bottom: 8rem !important; }
			&--l { margin-bottom: 14rem !important; }
		}

		&__vertical{
			&--n { margin-top: 0 !important; margin-bottom: 0 !important; }
			&--xs { margin-top: 0.8rem !important; margin-bottom: 0.8rem !important; }
			&--s { margin-top: 1.6rem; margin-bottom: 1.6rem !important; }
			&--m { margin-top: 2.4rem; margin-bottom: 2.4rem !important; }
			&--b { margin-top: 8rem; margin-bottom: 8rem !important; }
			&--l { margin-top: 14rem; margin-bottom: 14rem !important; }
		}

		&__right{
			&--n { margin-right: 0 !important; }
			&--xs { margin-right: 0.8rem !important; }
			&--s { margin-right: 1.6rem !important; }
			&--m { margin-right: 2.4rem !important; }
			&--b { margin-right: 8rem !important; }
			&--l { margin-right: 14rem !important; }
		}

		&__left{
			&--n { margin-left: 0 !important; }
			&--xs { margin-left: 0.8rem !important; }
			&--s { margin-left: 1.6rem !important; }
			&--m { margin-left: 2.4rem !important; }
			&--b { margin-left: 8rem !important; }
			&--l { margin-left: 14rem !important; }
		}

		&__horizontal{
			&--n { margin-right: 0 !important; margin-left: 0 !important; }
			&--xs { margin-right: 0.8rem !important; margin-left: 0.8rem !important; }
			&--s { margin-right: 1.6rem !important; margin-left: 1.6rem !important; }
			&--m { margin-right: 2.4rem !important; margin-left: 2.4rem !important; }
			&--b { margin-right: 8rem !important; margin-left: 8rem !important; }
			&--l { margin-right: 14rem !important; margin-left: 14rem !important; }
		}
	}

	&-p{
		&-t{
			&-n { padding-top: 0 !important; }
			&-s { padding-top: 1.6rem !important; }
			&-m { padding-top: 2.4rem !important; }
			&-b { padding-top: 8rem !important; }
			&-l { padding-top: 14rem !important; }
		}

		&-b{
			&-n { padding-bottom: 0 !important; }
			&-s { padding-bottom: 1.6rem !important; }
			&-m { padding-bottom: 2.4rem !important; }
			&-b { padding-bottom: 8rem !important; }
			&-l { padding-bottom: 14rem !important; }
		}

		&-v{
			&-n { padding-top: 0 !important; padding-bottom: 0 !important; }
			&-xs { padding-top: 0.8rem !important; padding-bottom: 0.8rem !important; }
			&-s { padding-top: 1.6rem !important; padding-bottom: 1.6rem !important; }
			&-m { padding-top: 2.4rem !important; padding-bottom: 2.4rem !important; }
			&-b { padding-top: 8rem !important; padding-bottom: 8rem !important; }
			&-l { padding-top: 14rem !important; padding-bottom: 14rem !important; }
		}

		&-r{
			&-n { padding-right: 0 !important; }
			&-s { padding-right: 1.6rem !important; }
			&-m { padding-right: 2.4rem !important; }
			&-b { padding-right: 8rem !important; }
			&-l { padding-right: 14rem !important; }
		}

		&-l{
			&-n { padding-left: 0 !important; }
			&-s { padding-left: 1.6rem !important; }
			&-m { padding-left: 2.4rem !important; }
			&-b { padding-left: 8rem !important; }
			&-l { padding-left: 14rem !important; }
		}

		&-h{
			&-n { padding-right: 0 !important; padding-left: 0 !important; }
			&-xxs { padding-right: 0.8rem !important; padding-left: 0.8rem !important; }
			&-s { padding-right: 1.6rem !important; padding-left: 1.6rem !important; }
			&-m { padding-right: 2.4rem !important; padding-left: 2.4rem !important; }
			&-b { padding-right: 8rem !important; padding-left: 8rem !important; }
			&-l { padding-right: 14rem !important; padding-left: 14rem !important; }
			&-x { padding-right: 20rem !important; padding-left: 20rem !important; }
		}
	}

	&__d {
		&__flex {display: flex ;}
		&__block {display: block ;}
		&__inline {display: inline ;}
		&__inline-block {display: inline-block ;}
		&__inline-flex {display: inline-flex ;}
	}

	&__align {
		&__start {align-items: flex-start;}
		&__center {align-items: center;}
		&__end {align-items: flex-end;}
	}

	&__justify {
		&__start {justify-content: flex-start;}
		&__center {justify-content: center;}
		&__end {justify-content: flex-end;}
		&__between{justify-content: space-between;}
		&__around {justify-content: space-around;}
	}
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.vis{
	&--hide{opacity: 0;}
	&--show{opacity: 1;}
}
;@import "sass-embedded-legacy-load-done:6";