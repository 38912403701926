.spinner{
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: rgba($color-white, 0.48);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
	opacity: 0;
	z-index: -100;

	&--show{
		opacity: 1;
		z-index: 1000;
	}

	&__container{
		height: 8rem;
		width: 8rem;
	}
}
;@import "sass-embedded-legacy-load-done:15";