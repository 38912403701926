.footer{
	position: relative ;
	padding: 20rem 8rem ;
	width: 100%;
	background-color: $color-blue ;
	// background-image: url('../../../assets/images/footer-bg-top.svg') , url('../../../assets/images/footer-bg-bottom.svg');
	// background-size: 100% auto,100% auto;
	// background-position: top,bottom;
	// background-repeat: no-repeat;
	display: flex ;
	align-items: center ; 
	flex-direction: column ;

	@include respond(tabLand){ padding: 20rem 4rem; }
	@include respond(phone){ padding: 16rem 4rem ;}

	&::before {
		content: "" ;
		position: absolute ;
		top : -4px ;
		left: 0 ;
		width : 100% ;
		height: 100% ;
		pointer-events: none ;
		background-image: url('../../../assets/images/footer-bg-top.svg');
		background-size: 100% auto;
		background-position: top left;
		background-repeat: no-repeat;

		@include respond(tabPort){ background-size: 120% auto;}	
		@include respond(phone)  { background-size: 200% auto;}
	}

	&::after {
		content: "" ;
		position: absolute ;
		bottom :0 ;
		left: 0 ;
		width : 100% ;
		height: 100% ;
		pointer-events: none ;
		background-image: url('../../../assets/images/footer-bg-bottom.svg');
		background-size: 100% auto;
		background-position: bottom right;
		background-repeat: no-repeat;

		@include respond(tabPort){ background-size: 120% auto;}	
		@include respond(phone)  { background-size: 200% auto;}
	}

	&__row{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 96rem ;
		width: 100% ;

		&:not(:last-child){ margin-bottom: 4rem; }

		@include respond(phone){
			&:nth-child(2){
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				.footer__row__item{
					&:not(:last-child){
						margin-bottom: 3.6rem;
					}
				}
			}
		}

		&__item{

			&__socials{
				display: flex;
				flex-direction: row;
				align-items: center;

				&__item{
					cursor: pointer;
					border: 0.24rem solid $color-white ;
					height: 6rem;
					width: 6rem;
					border-radius: 100rem ;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: transform 0.15s cubic-bezier(0,1.4,0.3,1.4);

					&:hover {
						transform: scale(1.1) ;
						background-color: $color-white ;
						>svg {
							stroke : $color-blue ;
						}
					}

					&:not(:last-child){margin-right: 2.4rem;}

					>svg{
						height: 3.2rem;
						width: 3.2rem;
						object-fit: contain;
					}
				}
			}

			&__logo {
				display: flex ;
				align-items: center ;
				> * + * {
					margin-left: 2rem ;
				}
			}

			&__links{
				font-family: 'Josefin Sans', sans-serif;
				font-size: 2rem ;
				
				&__head{
					margin-bottom: 2.4rem;
					@include respond(phone){margin-bottom: 1.2rem;}
				}
	
				&__item{
					margin-bottom: 2rem !important;
					cursor: pointer;
				}
			}
		}
	}
}
;@import "sass-embedded-legacy-load-done:10";