.sidepanel {
	top : 0 ;
	position: fixed ;
	height: 100% ;
	width : 100% ;
	max-width: 40rem ;
	background-color: $color-white ;
	box-shadow: $shadow-md ;
	transition: all 150ms ease-out ;
	z-index: 100 ;
	display: flex ;
	flex-direction: column ;

	@include respond(phone) {
		max-width: 100% ;
	}
	
	&--right {
		right: 0 ;
		transform: translate(calc(100% + 4rem));
	}

	&--left {
		left: 0 ;
		transform: translate(calc(100% + 4rem));
	}

	&--right-bottom {
		right: 0 ;
		transform: translate(calc(100% + 4rem));

		@include respond(phone) {
			top: unset ;
			bottom: 0 ; 
			left: 0 ;
			width: 100% ;
			height: min-content  ;
			transform: translateY(calc(100% + 4rem));
		}
	}

	&--open {
		transform: translate(0);
	}

	&__header {
		display: flex ;
		padding: 1rem ;
		border-bottom: 0.2rem solid $color-neutral-200 ;

		&__title {
			flex: 1 ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			color: $color-neutral-700 ;
			padding-right: 5rem ;
		}
	}

	&__body {
		flex: 1 ;
		overflow-y: auto ;
	}
}

[data-theme="dark"] .sidepanel {
	background-color: $color-neutral-900 ;

	&__header {
		border-bottom-color: $color-black ;
		&__title {
			color: $color-neutral-100 ;
		}
	}
} 
;@import "sass-embedded-legacy-load-done:9";