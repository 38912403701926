.backdrop{
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.4s ease-in-out;
	z-index: -1;
	opacity: 0;
	
	&--show{
		opacity: 1;
		z-index: 800;
	}

	&__container{
		background-color: rgba($color-black, 0.5);
		height: 100vh;
		width: 100vw;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;

		&--nodismiss{
			cursor: default;
		}
	}

	&__content{
		
	}
}
;@import "sass-embedded-legacy-load-done:7";